import { HglBuildingBlock, HglBuildingBlockType } from '../model/element.types';
import { HglUiElement } from './element.types';

export class StepElement implements HglUiElement {
  public id: string;
  public type: HglBuildingBlockType;
  public htmlElement: HTMLElement | null = null;
  public content: string;

  constructor({ id, type, content }: any) {
    this.id = id;
    this.type = type;
    this.content = content;
    let textBox = document.createElement('p');
    textBox.className = 'hgl-element-reset hgl-block-text step';
    textBox.innerHTML = content;
    textBox.id = id;
    this.htmlElement = textBox;
  }

  public lightUpdate(data: any) {}
  
  public update({ id, content }: any) {
    if (id !== this.id) {
      return
    }
    this.htmlElement!.innerHTML = content;
  }

  public destroy(): void {
    this.htmlElement = null;
  }
}
