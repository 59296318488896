import {
  HglBuildingBlock,
  HglChildElementType,
  HglElementType,
} from "../model/element.types";
import { ContainerElement } from "./container.element";
import { ElementFactory } from "./element.factory";
import { HglGuideElementFactory } from "./guide-element.factory";

export class HglUiGuideContainerElement extends ContainerElement {
  constructor(
    data: any,
    parentType: HglElementType,
    childType: HglChildElementType,
    factory: ElementFactory,
    protected topLevel = false
  ) {
    super(data, parentType, childType, factory, topLevel);
  }

  public override addElements(factory: ElementFactory): void {
    const isFreeFlow = (factory as HglGuideElementFactory).isFreeFlow
    if (!isFreeFlow) {
      const arrow = document.createElement("div");
      arrow.className = "hgl-element-reset hgl-arrow hgl-arrow--top";
      this.htmlElement.appendChild(arrow);
    }

    this.content.forEach((block) => {
      if (!this.isFooterElement(block)) {
        const htmlEl = factory.create(block);
        if (!htmlEl.htmlElement) {
          return;
        }
        this.childElements.push(htmlEl);
        this.htmlElement.appendChild(htmlEl.htmlElement);
      }
    });

    const footerItems = this.content.filter(this.isFooterElement);
    const step: HglBuildingBlock = {
      id: "hgl-element-step-button",
      type: "step",
      content: "",
    };
    footerItems.push(step);

    if (footerItems.length) {
      const footerEls =
        this.htmlElement.getElementsByClassName("hgl-footer-element");

      let footer: HTMLElement;
      if (footerEls.length) {
        footer = footerEls[0] as HTMLElement;
      } else {
        footer = document.createElement("div");
        footer.id = `hgl-footer-element`;
        footer.className = `hgl-footer-element`;
        this.htmlElement.appendChild(footer);
      }
      footerItems.sort((a, b) => {
        if (a.type === 'step') {
          return 1
        }
        if (b.type === 'step') {
          return -1
        }
        return 0
      })
      footerItems.forEach((el) => {
        const htmlEl = factory.create(el);
        if (!htmlEl.htmlElement) {
          return;
        }
        this.childElements.push(htmlEl);
        footer.appendChild(htmlEl.htmlElement);
      });
      if (footerItems.length === 1) {
        footer.style.justifyContent = 'end';
      }
    }

    if (this.topLevel || this.type === 'header') {
      (this.htmlElement.lastChild as HTMLElement).style.paddingBottom = '10px'
      let firstIncludedElement: HTMLElement | null = null
      this.htmlElement.childNodes.forEach(node => {
        const element = (node as HTMLElement)
        
        const isExcluded = ['video', 'arrow', 'image', 'close'].find(clazz => element.className.includes(clazz))
        const isHeader = this.type === 'header' || element.className.includes('header')
        const isMedia = ['video', 'image'].find(clazz => element.className.includes(clazz))
        if (isMedia && !firstIncludedElement) {
          firstIncludedElement = element
          element.style.borderTopRightRadius = factory.themeApplier.theme.radius
          element.style.borderTopLeftRadius = factory.themeApplier.theme.radius
        }

        if (!isExcluded && !firstIncludedElement) {
          firstIncludedElement = element
          if (!isHeader) {
            firstIncludedElement.style.paddingTop = '10px'
          }
        }
      })
    }
  }

  public override destroy(id?: string): void {
    const footer =
      this.htmlElement.getElementsByClassName("hgl-footer-element")[0];
    if (!id) {
      this.childElements.filter(this.isFooterElement).forEach((el) => {
        try {
          footer.removeChild(el.htmlElement!);
        } catch(err) {}
        el.destroy();
      });
      try {
        this.htmlElement.removeChild(footer);
      } catch(err) {}
      this.childElements = this.childElements.filter((el) => el.id !== id);
      super.destroy();
      return;
    }

    const child = this.childElements
      .filter(this.isFooterElement)
      .find((el) => el.id === id);
    if (child) {
      footer.removeChild(child.htmlElement!);
      child.destroy();
      this.childElements = this.childElements.filter((el) => el.id !== id);
      return;
    }

    super.destroy(id);
  }

  private isFooterElement({ type }: HglBuildingBlock): boolean {
    return ["next", "btn_end", "step"].includes(type);
  }
}
