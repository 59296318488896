import { LocalStorageHelper } from "../../helpers/local-storage.helper";
import {
  HglBuildingBlock,
  HglChildElement,
  HglElement,
} from "../../model/element.types";
import { ContainerElement } from "../../ui-elements/container.element";
import { ElementFactory } from "../../ui-elements/element.factory";
import { HglUiElementActions } from "../../ui-elements/element.types";
import { Orchestrator } from "./orchestrator.types";

export class ChecklistOrchestrator implements Orchestrator {
  private elementFactory: ElementFactory | null = null;

  private containerElement: ContainerElement | null = null;

  constructor(
    private localStorageHelper: LocalStorageHelper,
    private onClose: () => void
  ) {}

  public show(element: HglElement, data?: { id: string }): void {
    const actions: HglUiElementActions = {
      onNext: () => {},
      onEnd: this.onClose!,
      onUpdated: (id: string) => {
        const children = this.containerElement?.childElements;
        children?.forEach((el) => {
          el.lightUpdate({ ...el, data: { isClicked: el.id === id } });
        });
      },
    };
    this.elementFactory = new ElementFactory(
      element.type,
      element.children[0].type,
      actions,
      element.children[0].config,
      element.theme
    );

    if (data?.id) {
      this.localStorageHelper.updateChecklist(element.id, data.id);
    }
    const checklist = this.localStorageHelper.getChecklist(element.id);
    const blocks = element.children[0].blocks[0].content as HglBuildingBlock[];
    const checklistBlocks = blocks.filter(({ type }) => type === "check");
    checklistBlocks.forEach(({ data }) => {
      data.isChecked = false;
      data.lastChecked = false;
      data.isClicked = false;
    });
    checklist?.checks?.forEach(({ id }) => {
      const foundBlock = checklistBlocks.find((block) => block.id === id);
      if (foundBlock) {
        foundBlock.data.isChecked = true;
      }
    });
    const lastChecked = data?.id && checklistBlocks.find(({ id }) => id === data.id);
    if (lastChecked) {
      lastChecked.data.lastChecked = true;
    }
    const nextBlock = checklistBlocks.find(({ data }) => !data.isChecked);
    if (nextBlock) {
      nextBlock.data.isClicked = true;
    }
    this.update(element.children[0]);
  }

  public update(childElement: HglChildElement): void {
    if (this.containerElement?.htmlElement) {
      document.body.removeChild(this.containerElement!.htmlElement);
      this.containerElement!.destroy();
    }

    this.elementFactory?.updateConfig(childElement.config)
    this.containerElement = this.elementFactory!.createContainer(
      childElement.blocks[0]
    );
    document.body.appendChild(this.containerElement.htmlElement);
    this.positionChecklist(childElement);
  }

  public close(): void {
    if (!this.containerElement) {
      return;
    }
    this.containerElement.destroy();
    document.body.removeChild(this.containerElement.htmlElement);
    this.containerElement = null;
  }

  private positionChecklist(childElement: HglChildElement): void {
    const position = childElement.data.position;
    const style = this.containerElement!.htmlElement.style;
    style.animationDuration = "300ms";
    const rect = this.containerElement!.htmlElement.getBoundingClientRect();

    const verticalPosition = position.split(" ")[0];
    switch (verticalPosition) {
      case "top": {
        style.top = "1rem";
        style.bottom = "unset";
        style.transform = "unset";
        style.animationName = "fadeInTop";
        break;
      }
      case "center": {
        if (position.split(" ").length === 1) {
          style.left = `calc((100vw - ${rect.width}px)/2)`;
          style.top = `calc((100vh - ${rect.height}px)/2)`;
          style.right = "unset";
          style.bottom = "unset";
          style.animationName = "fadeInBottom";
          return;
        } else {
          style.top = `calc((100vh - ${rect.height}px)/2)`;
          style.bottom = "unset";
        }
        break;
      }
      case "bottom": {
        style.bottom = "1rem";
        style.top = "unset";
        style.transform = "unset";
        style.animationName = "fadeInBottom";
        break;
      }
    }
    const horizontalPosition = position.split(" ")[1];
    switch (horizontalPosition) {
      case "left": {
        style.left = "1rem";
        style.right = "unset";
        style.animationName = "fadeInLeft";
        break;
      }
      case "center": {
        style.left = `calc((100vw - ${rect.width}px)/2)`;
        style.right = "unset";
        break;
      }
      case "right": {
        style.right = "1rem";
        style.left = "unset";
        style.animationName = "fadeInRight";
        break;
      }
    }
  }
}
