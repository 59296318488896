import { LocalStorageHelper } from "../../helpers/local-storage.helper";
import {
  HglChildElement,
  HglElement
} from "../../model/element.types";
import { ContainerElement } from "../../ui-elements/container.element";
import { ElementFactory } from "../../ui-elements/element.factory";
import { Orchestrator } from "./orchestrator.types";

export class BubbleOrchestrator implements Orchestrator {
  private containerElement: ContainerElement | null = null;
  private factory: ElementFactory | null = null;

  constructor(
    private localStorageHelper: LocalStorageHelper,
    private onClose: (() => void) | null
  ) {}

  public show(element: HglElement): void {
    const actions = {
      onNext: () => {},
      onEnd: this.onClose!,
    };
    this.factory = new ElementFactory(
      element.type,
      element.children[0].type,
      actions,
      element.children[0].config,
      element.theme,
    );

    this.update(element.children[0])
  }

  public update(childElement: HglChildElement): void {
    if (this.containerElement?.htmlElement) {
      document.body.removeChild(this.containerElement!.htmlElement);
      this.containerElement!.destroy()
    }
    
    this.factory?.updateConfig(childElement.config)
    this.containerElement = this.factory!.createContainer(childElement.blocks[0]);
    document.body.appendChild(this.containerElement.htmlElement);
    
    this.positionBubble(childElement);
  }

  public close(): void {
    if (!this.containerElement) {
      return;
    }
    this.containerElement!.destroy();
    document.body.removeChild(this.containerElement.htmlElement);
    this.containerElement = null;
  }

  private positionBubble(childElement: HglChildElement): void {
    const position = childElement.data.position;
    const style = this.containerElement!.htmlElement.style;
    style.animationDuration = "300ms";
    const rect = this.containerElement!.htmlElement.getBoundingClientRect();

    const verticalPosition = position.split(" ")[0];
    switch (verticalPosition) {
      case "top": {
        style.top = "1rem";
        style.bottom = "unset";
        style.transform = "unset";
        style.animationName = "fadeInTop";
        break;
      }
      case "center": {
        if (position.split(" ").length === 1) {
          style.left = `calc((100vw - ${rect.width}px)/2)`;
          style.top = `calc((100vh - ${rect.height}px)/2)`;
          style.right = "unset";
          style.bottom = "unset";
          style.animationName = "fadeInBottom";
          return;
        } else {
          style.top = `calc((100vh - ${rect.height}px)/2)`;
          style.bottom = "unset";
        }
        break;
      }
      case "bottom": {
        style.bottom = "1rem";
        style.top = "unset";
        style.transform = "unset";
        style.animationName = "fadeInBottom";
        break;
      }
    }
    const horizontalPosition = position.split(" ")[1];
    switch (horizontalPosition) {
      case "left": {
        style.left = "1rem";
        style.right = "unset";
        style.animationName = "fadeInLeft";
        break;
      }
      case "center": {
        style.left = `calc((100vw - ${rect.width}px)/2)`;
        style.right = "unset";
        break;
      }
      case "right": {
        style.right = "1rem";
        style.left = "unset";
        style.animationName = "fadeInRight";
        break;
      }
    }
  }

}
