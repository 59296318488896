export class LocalStorageHelper {
  private readonly localStorageHelperPrefix = "-lsh-";
  private readonly nextGuideElementPrefix = "nge";
  private readonly shownElementIdPrefix = "sei~";
  private readonly userIdPrefix = "iduu~";
  private readonly userJoinedDatePostfix = "ujd";
  private readonly lastUpdatePostfix = "lu";
  private readonly highlightraDataPostfix = "hgldt";
  private readonly checklistDataPostfix = "hglckl";

  constructor(private prefix: string) {}

  public addNextGuideElementId(
    guideId: string,
    nextElementId: string | null
  ): void {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.nextGuideElementPrefix;
    if (nextElementId) {
      localStorage.setItem(key, `${guideId}_${nextElementId}`);
    } else {
      this.removeNextGuideElementId();
    }
  }

  public removeNextGuideElementId(): void {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.nextGuideElementPrefix;
    localStorage.removeItem(key);
  }

  public getNextGuideElementId(): LocalStorageGuide | null {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.nextGuideElementPrefix;
    const guide = localStorage.getItem(key);
    if (guide) {
      return {
        guideId: guide.split("_")[0],
        nextElementId: guide.split("_")[1],
      };
    }
    return null;
  }

  public addElementShownDate(elementId: string): void {
    const key =
      this.prefix +
      this.localStorageHelperPrefix +
      this.shownElementIdPrefix +
      elementId;

    localStorage.setItem(key, "" + Date.now());
  }

  public removeElementShownDate(elementId: string): void {
    const key =
      this.prefix +
      this.localStorageHelperPrefix +
      this.shownElementIdPrefix +
      elementId;

    localStorage.removeItem(key);
  }

  public getElementShownDate(elementId: string): Date | null {
    const key =
      this.prefix +
      this.localStorageHelperPrefix +
      this.shownElementIdPrefix +
      elementId;

    const date = localStorage.getItem(key);
    if (date) {
      return new Date(date);
    }
    return null;
  }

  public createUser(): void {
    const key = this.prefix + this.localStorageHelperPrefix + this.userIdPrefix;

    const host = window.location.host;
    const randomNumber = Math.floor(Math.random() * 99999);

    const ts = Date.now();
    localStorage.setItem(key, `${host}${ts}${randomNumber}`);
    const key2 = key + this.userJoinedDatePostfix;
    localStorage.setItem(key2, `${ts}`);
  }

  public getUser(): { uid: string | null; ts: string | null } {
    const key = this.prefix + this.localStorageHelperPrefix + this.userIdPrefix;

    const key2 = key + this.userJoinedDatePostfix;
    return {
      uid: localStorage.getItem(key),
      ts: localStorage.getItem(key2),
    };
  }

  public setLastUpdate(): void {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.lastUpdatePostfix;
    localStorage.setItem(key, Date.now() + "");
  }

  public canUpdate(): boolean {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.lastUpdatePostfix;
    const lastUpdated = localStorage.getItem(key);

    const oneHourMillis = 60 * 60 * 1000; // one hour in millis
    return !lastUpdated || parseFloat(lastUpdated) + oneHourMillis < Date.now();
  }

  public updateCachedData(dataStringified: string): void {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.highlightraDataPostfix;
    localStorage.setItem(key, dataStringified);
  }

  public getCachedData(): string {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.highlightraDataPostfix;
    const dataStringified = localStorage.getItem(key);

    return dataStringified!;
  }

  public updateChecklist(
    checklistId: string,
    checkId: string,
    checked = true
  ): void {
    const key =
      this.prefix + this.localStorageHelperPrefix + this.checklistDataPostfix;

    const checklistArrayJson = localStorage.getItem(key);

    let checklistArray: Checklist[] = [];
    if (checklistArrayJson) {
      checklistArray = JSON.parse(checklistArrayJson).checklist;
    }

    let checklist = checklistArray.find(({ id }) => id === checklistId);

    if (!checklist) {
      checklist = { id: checklistId, checks: [{ id: checkId }] };
      checklistArray.push(checklist);
    }

    if (checked) {
      checklist.checks.push({ id: checkId });
    } else {
      checklist.checks = checklist.checks.filter(({ id }) => id !== checkId);
    }

    const checklistJson = JSON.stringify({ checklist: checklistArray })
    localStorage.setItem(key, checklistJson)
  }

  public getChecklist(checklistId: string): Checklist | undefined {
    const key = this.prefix + this.localStorageHelperPrefix + this.checklistDataPostfix;

    const checklistArrayJson = localStorage.getItem(key);

    let checklistArray: Checklist[] = [];
    if (checklistArrayJson) {
      checklistArray = JSON.parse(checklistArrayJson).checklist;
    }

    return checklistArray.find(({ id }) => id === checklistId);
  }
}

export interface Checklist {
  id: string;
  checks: { id: string }[];
}

export interface LocalStorageGuide {
  guideId: string;
  nextElementId: string;
}
