import {
  ElementConfig,
  HglBuildingBlock,
  HglChildElementType,
  HglElementType,
  ThemeConfig,
} from "../model/element.types";
import { CheckElement } from "./check.element";
import { CloseButtonElement } from "./close-button.element";
import { ContainerElement } from "./container.element";
import { DelimiterElement } from "./delimiter.element";
import { HglUiElement, HglUiElementActions } from "./element.types";
import { EndButtonElement } from "./end-button.element";
import { ImageElement } from "./image.element";
import { NextButtonElement } from "./next-button.element";
import { StepElement } from "./step.element";
import { TextElement } from "./text.element";
import { ThmeeApplier } from "./theme.applier";
import { VideoElement } from "./video.element";

export class ElementFactory {
  public themeApplier: ThmeeApplier;

  constructor(
    protected parentType: HglElementType,
    protected childType: HglChildElementType,
    protected actions: HglUiElementActions,
    protected config: ElementConfig,
    protected theme: ThemeConfig
  ) {
    this.themeApplier = new ThmeeApplier(theme, config);
  }

  public updateConfig(config: ElementConfig) {
    this.config = config
    this.themeApplier.updateConfig(config)
  }

  public createContainer(block: HglBuildingBlock): ContainerElement {
    const data = { ...block, ...this.actions };
    const element = new ContainerElement(
      data,
      this.parentType,
      this.childType,
      this,
      true
    );

    this.themeApplier.applyConfig(element);
    return element;
  }

  public create(block: HglBuildingBlock): HglUiElement {
    const data = { ...block, ...this.actions };
    let element: HglUiElement;
    switch (block.type) {
      case "heading":
      case "subheading":
      case "body":
        element = new TextElement(data);
        break;
      case "btn_end":
        element = new EndButtonElement(data);
        break;
      case "next":
        element = new NextButtonElement(data);
        break;
      case "close":
        element = new CloseButtonElement(data);
        break;
      case "step":
        element = new StepElement(data);
        break;
      case "check":
        element = new CheckElement(data, this);
        break;
      case "delimiter":
        element = new DelimiterElement(data);
        break;
      case "header":
      case "column":
      case "row":
        element = new ContainerElement(
          data,
          this.parentType,
          this.childType,
          this
        );
        break;
      case "image":
        element = new ImageElement(data);
        break;
      case "video":
        element = new VideoElement(data);
        break;
      default:
        throw new Error("Unsupported building block");
    }
    return element;
  }
}
