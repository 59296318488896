import { LocalStorageHelper } from "../helpers/local-storage.helper";
import { HglElement } from "../model/element.types";

export class TriggerButtonElement {
  private container: HTMLElement | null = null;

  constructor(private localStorageHelper: LocalStorageHelper) {}

  public show(
    text: string | undefined,
    elements: HglElement[],
    show: (id: string) => void
  ): Node | null {
    const existing = document.body.getElementsByClassName("hgl-trigger-button");
    if (existing.length) {
      document.body.removeChild(existing[0])
    }

    if (elements.length < 1) {
        return null
    }
    
    const container = document.createElement("div");
    const containerBaseClasses = "hgl-element-reset hgl-trigger-button";
    container.className = containerBaseClasses;
    container.innerHTML = `<div id="icon"></div>${text ? text : "Learn more"}`;
    container.style.backgroundColor = elements[0].theme.colors.primary
    container.style.color = elements[0].theme.colors.primaryLight

    const toursContainer = document.createElement("div");
    toursContainer.className =
      "hgl-element-reset hgl-drop-up hgl-drop-up--hidden";
    const contentContainer = document.createElement("div");
    contentContainer.className = "hgl-element-reset hgl-drop-up-content";

    elements.forEach((element) => {
      const span = document.createElement("span");
      span.innerText = element.name;
      span.addEventListener("click", () => {
        this.localStorageHelper.removeElementShownDate(element.id);
        show(element.id);
      });
      contentContainer.appendChild(span);
    });

    this.container = container;
    document.body.appendChild(container);

    if (elements.length === 1) {
      container.addEventListener("click", () => {
        this.localStorageHelper.removeElementShownDate(elements[0].id);
        show(elements[0].id);
      });
      return container;
    }

    container.addEventListener("click", () => {
      const hiddenMaybe = toursContainer.className.includes("--hidden")
        ? ""
        : "hgl-drop-up--hidden";
      toursContainer.className = `hgl-element-reset hgl-drop-up ${hiddenMaybe}`;
      container.className = `${containerBaseClasses} ${
        hiddenMaybe.length ? "" : "hgl-trigger-button--expanded"
      }`;
    });
    toursContainer.appendChild(contentContainer);
    container.appendChild(toursContainer);

    return container;
  }

  public destroy(): void {
    if (!this.container) {
      return;
    }

    if (!this.container.parentNode) {
      return;
    }

    this.container.parentNode.removeChild(this.container);
    this.container = null;
  }
}
