import { HglBuildingBlockType } from '../model/element.types';
import { HglUiElement } from './element.types';

export class CloseButtonElement implements HglUiElement {
  public id: string;
  public type: HglBuildingBlockType
  public htmlElement: HTMLElement | null = null;
  public content = undefined;

  private onCloseListener: ((event: Event) => void) | null = null;

  constructor({ type, onEnd }: any) {
    this.id = 'hgl-block-close-button';
    this.type = type;
    const closeButton = document.createElement('div');
    closeButton.className = 'hgl-element-reset hgl-block-close-button';
    closeButton.id = 'hgl-block-close-button';
    const closeButtonX = document.createElement('span');
    closeButtonX.className = 'hgl-element-reset hgl-block-close-button-element';
    closeButton.appendChild(closeButtonX);

    this.onCloseListener = (event) => {
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();

      onEnd();
    };
    closeButton.addEventListener('click', this.onCloseListener);

    this.htmlElement = closeButton;
  }

  public lightUpdate(data: any) {}
  
  public update({ id }: any) {
    if (id !== this.id) {
      return
    }
    this.htmlElement!.removeEventListener('click', this.onCloseListener!);
    this.onCloseListener = (event) => {
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();
    }
    this.htmlElement!.addEventListener('click', this.onCloseListener)
  }

  public destroy(): void {
    this.htmlElement?.removeEventListener('click', this.onCloseListener!);
    this.htmlElement = null;
  }
}
