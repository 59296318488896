import { DomEventHelper } from '../helpers/dom-event.helper';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { OverlayHelper } from '../helpers/overlay.helper';
import { HglChildElement, HglElement } from '../model/element.types';
import { BubbleOrchestrator } from './orchestrators/bubble.orchestrator';
import { ChecklistOrchestrator } from './orchestrators/checklist.orchestrator';
import { GuideOrchestrator } from './orchestrators/guide.orchestrator';
import { Orchestrator } from './orchestrators/orchestrator.types';

export class CoreOrchestrator {
  private currentOrchestrator: Orchestrator | null = null;

  constructor(
    private localStorageHelper: LocalStorageHelper,
    private domEventHelper: DomEventHelper,
    private overlayHelper: OverlayHelper,
    private onClose: () => void,
  ) {}

  public show(element: HglElement, data?: any) {
    this.currentOrchestrator = this.orchestratorFactory(element);
    this.currentOrchestrator.show(element, data);
  }

  public update(childElement: HglChildElement) {
    this.currentOrchestrator?.update(childElement);
  }

  public close() {
    this.currentOrchestrator?.close();
    this.currentOrchestrator = null;
  }

  private orchestratorFactory({ type }: HglElement): Orchestrator {
    switch (type) {
      case 'guide':
        return new GuideOrchestrator(this.localStorageHelper, this.overlayHelper, this.domEventHelper, this.onClose);
      case 'bubble':
        return new BubbleOrchestrator(this.localStorageHelper, this.onClose);
      case 'checklist':
        return new ChecklistOrchestrator(this.localStorageHelper, this.onClose)
    }
  }
}
