import { HglBuildingBlockType } from '../model/element.types';
import { HglUiElement } from './element.types';

export class TextElement implements HglUiElement {
  public id: string;
  public type: HglBuildingBlockType;
  public htmlElement: HTMLElement | null = null;
  public content: string;

  private MAPPING: any = {
    heading: 'h1',
    subheading: 'h3',
    body: 'p',
  };

  constructor({ id, type, content, data }: any) {
    this.id = id;
    this.type = type;
    this.content = content;
    let textBox = document.createElement(this.MAPPING[type]);

    textBox.className = `hgl-element-reset hgl-block-text hgl-block-text-${this.MAPPING[type]}`;
    textBox.innerHTML = content;
    
    if (data?.isHeaderChecked) {
      const header = document.createElement('div')
      header.className = 'hgl-element-reset hgl-block-header'
  
      this.htmlElement = header;
      header.appendChild(textBox)
      header.id = id;
      textBox.style.color = 'white'
    } else {
      textBox.id = id
      this.htmlElement = textBox;
    }

  }

  public lightUpdate(data: any) {}
  
  public update({ id, content, data }: any) {
    if (id !== this.id) {
      return
    }
    if (data?.isHeaderChecked) {
      (this.htmlElement!.firstChild! as HTMLElement).innerHTML = content;
    } else {
      this.htmlElement!.innerHTML = content;
    }
  }

  public destroy(): void {
    this.htmlElement = null;
  }
}
