import { HglBuildingBlockType } from "../model/element.types";
import { HglUiElement } from "./element.types";

export class ImageElement implements HglUiElement {
    public id: string;
    public type: HglBuildingBlockType
    public htmlElement: HTMLElement | null = null;
    public content!: string;
  
    constructor({ id, type, content, data }: any) {
      this.id = id;
      this.type = type;
      this.content = content;
      const image = document.createElement('img');
      image.src = content;
      image.className = 'hgl-element-reset hgl-block-image';
      image.id = id;
      
      const { width, height } = data
      image.style.width = `${width}px`
      // image.style.height = `${height}px`

      this.htmlElement = image
    }

    public lightUpdate(data: any) {}
  
    public update(): void {

    }

    public destroy(): void {

    }
}