import { HglBuildingBlockType } from "../model/element.types";
import { HglUiElement } from "./element.types";

export class VideoElement implements HglUiElement {
    public id: string;
    public type: HglBuildingBlockType
    public htmlElement: HTMLElement | null = null;
    public content!: string;
  
    constructor({ id, type, content, data }: any) {
      this.id = id;
      this.type = type;
      this.content = content;
      const video: HTMLVideoElement = document.createElement('video');
      video.src = content;
      video.className = 'hgl-element-reset hgl-block-video';
      video.id = id;
      video.muted = true
      video.loop = true
      video.autoplay = true
      video.playsInline = true
      video.oncanplay = () => video.play()
      video.onloadedmetadata = () => video.muted = true
      setTimeout(() => video.load())
      
      const { width, height } = data
      video.style.width = `${width}px`
      // video.style.height = `${height}px`

      this.htmlElement = video
    }

    public lightUpdate(data: any) {}
  
    public update(): void {

    }

    public destroy(): void {

    }
}