import "./demo.less";
import { HglChildElement } from "../shared/model/element.types";
import {
  findByElementPath,
  getElementPath,
} from "../shared/utils/common.utils";
import { ElementSelectionHelper } from "../stapler/element-selection.helper";
import { Main } from "../shared/main";
import { environment } from "../environments/environment";

class Demo extends Main {
  readonly homehostComm = environment.host;
  private elementSelectionHelper: ElementSelectionHelper;

  constructor() {
    super('demo-', true);
    this.elementSelectionHelper = new ElementSelectionHelper(this.overlayHelper);
    const messageListener = (message: any) => {
      // console.log('==script== message received', message);
      if (message.data.action) {
        this.run(message.data);
      }
    };
    window.addEventListener("message", messageListener);

    window.addEventListener(
      "unload",
      () => {
        this.elementSelectionHelper.destroy();
        window.removeEventListener("message", messageListener);
      },
      { once: true }
    );
    
    window.addEventListener('beforeunload', () => {
      this.sendMessage({ action: 'urlChangedDemo', urlChanged: null });  
    })
    this.setUrlChangeListener()
    this.sendMessage({ action: 'data' });  
  }

  private update(id: string, childElement: HglChildElement): void {
    const element = this.elements[0];
    if (element && this.orchestrators.has(element.id)) {
      this.orchestrators.get(element.id)!.update(childElement);
    }
  }

  public override show(id: string, onClose?: () => void): boolean {
    let showSuccessful = false
    const next = this.localStorageHelper.getNextGuideElementId()
    const isNextEqualToFirstElement = next && next.nextElementId === this.elements[0].children[0].id
    if (!isNextEqualToFirstElement || this.canShowOnCurrentSite(this.elements[0])) {
      showSuccessful = super.show(id, onClose)
    }
    this.sendMessage({ action: showSuccessful ? 'showing' : 'notShowing' });
    return showSuccessful;
  }

  public override close(id: string): void {
    super.close(id);
    this.elementSelectionHelper.destroy();
    this.sendMessage({ action: "finished" });
  }

  protected override updateShowToken(id: string): void {
    // empty
  }

  protected override showAutomaticOne(): void {
    // empty
  }

  protected override fetchDataAndStart(builder: boolean): void {
    // empty
  }

  private sendMessage(data: any) {
    try {
      // console.log('==script== message sent', data);
      window.parent.postMessage(data, this.homehostComm);
    } catch(err) {

    }
  }

  private run(data: any) {
    switch (data.action) {
      case "select":
        this.elementSelectionHelper.start((element: HTMLElement) => {
          const elementPath = getElementPath(element);
          const scrollPosition =
            document.documentElement.scrollTop || document.body.scrollTop;
          this.sendMessage({
            action: "selected",
            path: elementPath,
            scroll: scrollPosition,
          });
        });
        break;
      case "deselect":
        this.elementSelectionHelper.deselect(data.data.path);
        break;
      case "show":
        const unclosed = this.localStorageHelper.getNextGuideElementId()
        if (unclosed) {
          super.close(unclosed.nextElementId);
        }
        this.elements = [data.data];
        this.show(data.id);
        break;
      case "update":
        this.update(data.id, data.data);
        break;
      case "close":
        this.close(data.id);
        break;
      case "check":
        const path = data.data.path;
        const result = path && findByElementPath(path);
        let scrollPosition = 0
        if (result && result.type === 'id' ) {
          result.el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          setTimeout(() => {
            scrollPosition = document.documentElement.scrollTop || document.body.scrollTop
            this.sendMessage({ action: 'checked', valid: !!result, scroll: scrollPosition });
          }, 500)
        } else {
          this.sendMessage({ action: 'checked', valid: !!result, scroll: scrollPosition });
        }
        break;
      case "data":
        this.init([data.data])
        break;
    }
  }

  private setUrlChangeListener(): void {
    let oldHref: string | null = null
    setInterval(() => {
      if (oldHref !== document.location.href) {
        oldHref = document.location.href;
        this.sendMessage({ action: 'urlChangedDemo', urlChanged: oldHref });
      }
    }, 100);
  }
}

(<any>window).Demo = new Demo();
