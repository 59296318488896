import { HglBuildingBlock, HglBuildingBlockType } from '../model/element.types';
import { HglUiElement } from './element.types';

export class NextButtonElement implements HglUiElement {
  public id: string
  public type: HglBuildingBlockType
  public htmlElement: HTMLElement | null = null;
  public content: string;

  private onNextListener: ((event: Event) => void) | null = null;

  constructor({ id, type, content, onNext }: any) {
    this.id = id;
    this.type = type;
    this.content = content;
    let nextButton = document.createElement('button');
    nextButton.className = 'hgl-element-reset hgl-block-button';
    nextButton.innerHTML = content;
    nextButton.id = id;
    nextButton.style.display = content.length ? 'block' : 'none'

    this.onNextListener = (event) => {
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();

      onNext();
    };
    nextButton.addEventListener('click', this.onNextListener);

    this.htmlElement = nextButton;
  }

  public lightUpdate(data: any) {}
  
  public update({ id, content }: any) {
    if (id !== this.id) {
      return
    }
    this.htmlElement!.removeEventListener('click', this.onNextListener!);
    this.onNextListener = (event) => {
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();
    };
    this.htmlElement!.addEventListener('click', this.onNextListener!);
    this.htmlElement!.innerHTML = content;
    this.htmlElement!.style.display = content.length ? 'block' : 'none'
  }

  public destroy(): void {
    this.htmlElement?.removeEventListener('click', this.onNextListener!);
    this.htmlElement = null;
  }
}
