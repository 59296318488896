export class DomEventHelper {
  private eventsHandler: (() => void) | null = null;

  public setEventsHandler(evtHandler: () => void) {
    this.eventsHandler = evtHandler;

    addEventListener('load', this.eventsHandler, false);
    addEventListener('scroll', this.eventsHandler, false);
    addEventListener('resize', this.eventsHandler, false);
  }

  public removeEventsHandler() {
    if (!this.eventsHandler) {
      return;
    }
    removeEventListener('load', this.eventsHandler, false);
    removeEventListener('scroll', this.eventsHandler, false);
    removeEventListener('resize', this.eventsHandler, false);
  }
}
