import { ElementConfig, ThemeConfig } from "../model/element.types";
import { HglUiElement } from "./element.types";

export class ThmeeApplier {
  constructor(public theme: ThemeConfig, public config: ElementConfig) {}

  public updateConfig(config: ElementConfig) {
    this.config = config
  }

  readonly elementThemePropertiesConfig = [
    {
      classes: [
        ".hgl-guide-element",
        ".hgl-checklist-element",
        ".hgl-bubble-element",
      ],
      styles: [
        { property: "borderRadius", themeValue: "radius" },
        { property: "width", configValue: "width" },
      ],
    },
    {
      classes: [
        ".hgl-default-block-container-element--header",
        ".hgl-move-block-container-element--header",
        ".hgl-highlight-block-container-element--header",
        ".hgl-message-block-container-element--header",
        ".hgl-block-container-element--header",
      ],
      styles: [
        { property: "backgroundColor", themeValue: "colors.primary" },
        { property: "borderTopRightRadius", themeValue: "radius" },
        { property: "borderTopLeftRadius", themeValue: "radius" },
      ],
    },
    {
      classes: ['.hgl-block-delimiter'],
      styles: [{ property: "backgroundColor", themeValue: "colors.primary" }]
    },
    {
      classes: [
        ".hgl-block-text-h1",
        ".hgl-block-text-h2",
        ".hgl-block-text-h3",
      ],
      styles: [{ property: "color", themeValue: "colors.heading" }],
    },
    {
      classes: [".hgl-block-text-p"],
      styles: [{ property: "color", themeValue: "colors.text" }],
    },
    {
      classes: [".hgl-block-button"],
      styles: [
        { property: "color", themeValue: "colors.primaryLight" },
        { property: "backgroundColor", themeValue: "colors.primary" },
        { property: "borderRadius", themeValue: "radius" },
      ],
    },
    {
      classes: [
        ".hgl-default-block-container-element--header .hgl-block-text",
        ".hgl-move-block-container-element--header .hgl-block-text",
        ".hgl-highlight-block-container-element--header .hgl-block-text",
        ".hgl-message-block-container-element--header .hgl-block-text",
        ".hgl-block-container-element--header .hgl-block-text",
      ],
      styles: [{ property: "color", themeValue: "colors.primaryLight" }],
    },
    {
      classes: [".hgl-block-check"],
      styles: [{ property: "borderColor", themeValue: "colors.primary" }],
    },
    {
      classes: [".hgl-block-check--done"],
      styles: [
        { property: "backgroundColor", themeValue: "colors.primary" },
        { property: "borderColor", themeValue: "colors.primary" },
      ],
    },
    {
      classes: [".hgl-block-check-done"],
      styles: [{ property: "color", themeValue: "colors.primaryLight" }],
    },
    {
      variables: [
        { variable: "--hgl-primary-color", themeValue: "colors.primary" },
        { variable: "--hgl-primary-light-color", themeValue: "colors.primaryLight" },
      ],
    },
    {
      classes: [".hgl-arrow--colored"],
      styles: [{ property: "backgroundColor", themeValue: "colors.primary" }],
    },
  ];

  public applyConfig(hglUiElement: HglUiElement): void {
    const transparent = document.querySelector('.hgl-fullscreen-overlay .hgl-overlay.solid-border')
    if (transparent) {
      (transparent as HTMLElement).style.borderColor = this.theme.colors.primary
    }
    const htmlElement = hglUiElement.htmlElement!;
    htmlElement.style.borderRadius = this.theme.radius
    htmlElement.style.width = this.config.width

    this.elementThemePropertiesConfig.forEach(
      ({ classes, variables, styles }) => {
        classes?.forEach((clazz) => {
          const elements = this.findElements(htmlElement, clazz);
          elements && this.applyConfigToElements(elements, styles);
        });
        variables?.forEach(({ variable, themeValue }) => {
          const themeVal = this.getValue(this.theme, themeValue);
          if (themeVal) {
            document.documentElement.style.setProperty(variable, themeVal);
          }
        });
      }
    );
  }

  private findElements(
    htmlElement: HTMLElement,
    clazz: string
  ): NodeListOf<HTMLElement> {
    return htmlElement.querySelectorAll(clazz);
  }

  private applyConfigToElements(
    elements: NodeListOf<HTMLElement>,
    style: {
      property: string;
      themeValue?: string;
      configValue?: string;
      variable?: string;
    }[]
  ): void {
    for (let i = 0; elements && i < elements.length; i++) {
      const el = elements.item(i) as HTMLElement;
      style.forEach(({ property, themeValue, configValue, variable }) => {
        if (themeValue) {
          const themeVal = this.getValue(this.theme, themeValue);
          if (themeVal) {
            this.updateObjProp(el.style, themeVal, property);
          }
        }
        if (configValue) {
          const configVal = this.getValue(this.config, configValue);
          if (configVal) {
            this.updateObjProp(el.style, configVal, property);
          }
        }
      });
    }
  }

  private getValue = (obj: any, propPath: any): any => {
    const [head, ...rest] = propPath.split(".");
    return !rest.length ? obj[head] : this.getValue(obj[head], rest.join("."));
  };

  private updateObjProp = (obj: any, value: any, propPath: any) => {
    const [head, ...rest] = propPath.split(".");

    !rest.length
      ? (obj[head] = value)
      : this.updateObjProp(obj[head], value, rest.join("."));
  };
}
