import {
  ElementConfig,
  HglBuildingBlock,
  HglChildElementType,
  HglElementType,
  ThemeConfig
} from "../model/element.types";
import { ContainerElement } from "./container.element";
import { ElementFactory } from "./element.factory";
import { HglUiElementActions } from "./element.types";
import { HglUiGuideContainerElement } from "./guide-container.element";

export class HglGuideElementFactory extends ElementFactory {
  constructor(
    protected parentType: HglElementType,
    protected childType: HglChildElementType,
    protected actions: HglUiElementActions,
    protected config: ElementConfig,
    protected theme: ThemeConfig,
    public isFreeFlow: boolean
  ) {
    super(parentType, childType, actions, config, theme)
  }

  public override createContainer(block: HglBuildingBlock): ContainerElement {
    const data = { ...block, ...this.actions };
    const element = new HglUiGuideContainerElement(
      data,
      this.parentType,
      this.childType,
      this,
      true
    );
    this.themeApplier.applyConfig(element)
    
    return element
  }
}
