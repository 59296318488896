import { toPx } from '../utils/common.utils';

export class OverlayHelper {
  private rootOverlay: HTMLDivElement | null = null;
  private overlays: HTMLDivElement[] = [];

  // ********* ROOT overlay *********
  public getRootOverlay(): HTMLDivElement {
    if (this.rootOverlay) {
      return this.rootOverlay;
    }
    const overlay = document.createElement('div');
    overlay.className = 'hgl-element-reset hgl-fullscreen-overlay';
    document.body.appendChild(overlay);
    this.rootOverlay = overlay;
    overlay.style.animationName = 'fadeInCenter'
    overlay.style.animationDuration = '300ms'

    return overlay;
  }

  public closeRootOverlay() {
    if (this.rootOverlay === null) return;
    document.body.removeChild(this.rootOverlay);
    this.rootOverlay = null;
  }

  public updateOverlayPositions(elementRect: DOMRect) {
    for (let i = 0; i < this.overlays.length; i++) {
      this.updateOverlayPosition(this.overlays[i], elementRect, i);
    }
  }

  // ********* DIMMED overlays *********
  public createDimmedOverlays() {
    const array = []
    for (let i = 0; i < 4; i++) {
      const overlay = document.createElement('div');
      overlay.className = 'hgl-element-reset hgl-overlay dimmed';
      overlay.id = `hgl-overlay--dimmed-${i}`;
      overlay.style.animationName = 'fadeInCenter'
      overlay.style.animationDuration = '300ms'
      array.push(overlay);
      this.rootOverlay!.appendChild(overlay);
    }
    this.overlays.unshift(...array);
  }

  public closeDimmedOverlays() {
    this.overlays.forEach((el) => this.rootOverlay!.removeChild(el));
    this.overlays = [];
  }

  public updateOverlayPosition(
    overlay: HTMLDivElement,
    bounds: DOMRect,
    position: number
  ) {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    switch (position) {
      case 0:
        // case "top":
        overlay.style.height = toPx(bounds.top - 2);
        break;
      case 1:
        // case "right":
        overlay.style.top = toPx(bounds.top - 2);
        overlay.style.width = toPx(vw - bounds.right - 2);
        overlay.style.height = toPx(bounds.height + 4);
        overlay.style.left = toPx(bounds.right + 2);
        break;
      case 2:
        // case "bottom":
        overlay.style.height = toPx(vh - bounds.bottom - 2);
        overlay.style.top = toPx(bounds.bottom + 2);
        break;
      case 3:
        // case "left":
        overlay.style.top = toPx(bounds.top - 2);
        overlay.style.height = toPx(bounds.height + 4);
        overlay.style.width = toPx(bounds.left - 2);
        break;
      case 4:
        // case "middle":
        overlay.style.top = toPx(bounds.top - 2);
        overlay.style.left = toPx(bounds.left - 2);
        overlay.style.height = toPx(bounds.height + 4);
        overlay.style.width = toPx(bounds.right - bounds.left + 4);
        break;
    }
  }

  public getTransparentOverlay(): HTMLDivElement {
    let transOverlay = document.getElementById(
      'hgl-overlay-trans'
    ) as HTMLDivElement;
    if (transOverlay) {
      return transOverlay;
    }
    transOverlay = document.createElement('div');
    transOverlay.id = 'hgl-overlay-trans';
    transOverlay.className = 'hgl-element-reset hgl-overlay';
    this.overlays.push(transOverlay);
    this.rootOverlay!.appendChild(transOverlay);

    return transOverlay;
  }

  public addTransparentClickListener(onClick: (isClicked: boolean) => void): void {
    let transOverlay = document.getElementById(
      'hgl-overlay-trans'
    ) as HTMLDivElement;
    const clickListener = (event: any) => {
      const withoutPx = ((val: string) => parseInt(val.split('px')[0]))
      const style = transOverlay.style;
      if (
        event.x >= withoutPx(style.left) &&
        event.y >= withoutPx(style.top) &&
        event.x <= withoutPx(style.left) + withoutPx(style.width) &&
        event.y <= withoutPx(style.top) + withoutPx(style.height)
        ) {
        document.removeEventListener('click', clickListener)
        onClick(true);
      }
    }

    document.addEventListener('click', clickListener);
  }
}
