import { CoreOrchestrator } from '../shared/core/orchestrator.core';
import { HglElement } from '../shared/model/element.types';
import { LocalStorageHelper } from '../shared/helpers/local-storage.helper';
import { environment } from '../environments/environment';
import { TriggerButtonElement } from './ui-elements/trigger-button.element';
import { OverlayHelper } from './helpers/overlay.helper';
import { DomEventHelper } from './helpers/dom-event.helper';

export class Main {
  private readonly remoteHost = environment.host;
  protected orchestrators: Map<string, CoreOrchestrator> = new Map();
  protected overlayHelper: OverlayHelper;
  protected domEventHelper: DomEventHelper;

  protected elements: HglElement[] = [];
  public localStorageHelper: LocalStorageHelper

  constructor(prefix: string, builder: boolean) {
    this.localStorageHelper = new LocalStorageHelper(prefix)
    this.fetchDataAndStart(builder);
    this.overlayHelper = new OverlayHelper();
    this.domEventHelper = new DomEventHelper();
  }

  // after data loaded
  protected init(elements: HglElement[]): void {
    this.elements = elements;

    let href = ''
    setInterval(() => {
      if (href !== document.location.href) {
        href = document.location.href
        
        this.laterInit()
      }
    }, 500)
  }

  protected laterInit(): void {
    const localStorageGuide = this.localStorageHelper.getNextGuideElementId();
    if (localStorageGuide) {
      const element = this.elements.find(({ id }) => id === localStorageGuide.guideId);
      if (element) {
        this.orchestrators.get(element.id)?.close();
        this.orchestrators.delete(element.id);
        this.show(localStorageGuide.guideId, () => this.laterInit());
        return
      }
      this.localStorageHelper.removeNextGuideElementId()
    }

    const currentSitePartElements = this.elements.filter(this.canShowOnCurrentSite)
    const automaticElements = currentSitePartElements.filter(({ trigger }) => trigger.type === 'automated');
    if (automaticElements.length) {
      setTimeout(() => {
        this.showAutomaticOne(automaticElements, () => setTimeout(() => this.laterInit(), 300))
      }, 500)
    }
    this.localStorageHelper.createUser();
  }

  public showTriggerButton(config?: { buttonText: string }): void {
    let currentUrl = ''

    const triggerButton = new TriggerButtonElement(this.localStorageHelper)
    setInterval(() => {
      if (currentUrl === window.location.href) {
        return
      }
      triggerButton.destroy()
      currentUrl = window.location.href
      const appropriateElements = this.elements.filter(this.canShowOnCurrentSite)
      const filteredElements = appropriateElements.filter(({ type }) => type === 'guide')
      const triggerButtonElement = triggerButton.show(config?.buttonText, filteredElements, this.show.bind(this))

      if (!triggerButtonElement) {
        return
      }
      document.body.appendChild(triggerButtonElement);
    }, 500)
  }

  public show(id: string, onClose?: () => void, data?: any): boolean {
    const element = this.elements.find((el) => el.id === id);
    let showSuccessful = false
    if (element && !this.orchestrators.has(id)) {
      const orchestrator = new CoreOrchestrator(
        this.localStorageHelper,
        this.domEventHelper,
        this.overlayHelper,
        () => {
          this.onClose(id);
          onClose?.();
        }
      );
      this.orchestrators.set(id, orchestrator);
      orchestrator.show(element, data);
      this.updateShowToken(id)
      showSuccessful = true
    }
    return showSuccessful
  }

  public close(id: string): void {
    this.localStorageHelper.removeNextGuideElementId();
    const element = this.elements.find((el) => el.id === id);
    if (element) {
      this.orchestrators.get(id)?.close();
      this.orchestrators.delete(id);
    }
  }

  protected updateShowToken(id: string): void {
    this.localStorageHelper.addElementShownDate(id)
  }

  private onClose(id: string): void {
    this.close(id);
  }

  protected showAutomaticOne(automaticElements: HglElement[], onClose?: () => void): void {
    const first = automaticElements.find((el) => {
      const date = this.localStorageHelper.getElementShownDate(el.id)
      const showTimes = el.trigger.showTimes
      return !showTimes
        || showTimes === 'every'
        || showTimes === 'once' && !date
    })

    if (first) {
      this.show(first.id, onClose)
    }
  }

  protected fetchDataAndStart(builder: boolean) {
    if (!builder && !this.localStorageHelper.canUpdate()) {
      const data = this.localStorageHelper.getCachedData()
      if (data) {
        this.init(JSON.parse(data) as any);
      } else {
        this.init([]);
      }
      return
    }

    const license = document?.currentScript?.getAttribute('l');
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === XMLHttpRequest.DONE) {
        if (xmlHttp.status === 200) {
          const response: any = xmlHttp.responseText
          this.localStorageHelper.updateCachedData(response)
          this.init(JSON.parse(response) as any);
        } else {
          this.init([]);
        }
      }
    };
    xmlHttp.onerror = () => {
      this.init([])
    }
    xmlHttp.ontimeout = () => {
      this.init([])
    }
    const hostEncoded = encodeURIComponent(window.location.host);
    xmlHttp.open('GET', `${this.remoteHost}/crayon/element/${builder ? 'all' : 'published'}?h=${hostEncoded}&l=${license}`);
    xmlHttp.send();
    this.localStorageHelper.setLastUpdate()
  }

  protected canShowOnCurrentSite({ sitePart }: { sitePart: string }): boolean {
    let regexString = sitePart.replaceAll('\\', '')
    const currentSitePart = window.location.href

    // handle / at the end of location.href
    if (!regexString.endsWith('/?$')) {
      if (regexString.endsWith('$')) {
        regexString = regexString.slice(0, -1) + '/?$'
      } else if (!regexString.endsWith('/?')){
       regexString = regexString + '/?'
      }
    }

    // console.log("regex", regexString)
    // console.log("current", currentSitePart)
    const canShow = new RegExp(regexString).test(currentSitePart)
    return canShow
  }
}
