import { DomEventHelper } from "../../helpers/dom-event.helper";
import { updateGuideElementPosition } from "../../helpers/guide-position.helper";
import { LocalStorageHelper } from "../../helpers/local-storage.helper";
import { OverlayHelper } from "../../helpers/overlay.helper";
import {
  HglBuildingBlock,
  HglChildElement,
  HglElement
} from "../../model/element.types";
import { ContainerElement } from "../../ui-elements/container.element";
import { ElementFactory } from "../../ui-elements/element.factory";
import { HglUiElementActions } from "../../ui-elements/element.types";
import { HglGuideElementFactory } from "../../ui-elements/guide-element.factory";
import { findByElementPath } from "../../utils/common.utils";
import { Orchestrator } from "./orchestrator.types";
import confetti from 'canvas-confetti';

export class GuideOrchestrator implements Orchestrator {
  private elementFactory: ElementFactory | null = null;

  private hglElement!: HglElement;
  private hglCurrentChildElement!: HglChildElement;
  private containerElement!: ContainerElement;
  private currentClientElement: HTMLElement | null = null;
  private actions: HglUiElementActions | null = null;

  constructor(
    private localStorageHelper: LocalStorageHelper,
    private overlayHelper: OverlayHelper,
    private domEventHelper: DomEventHelper,
    private onClose: (() => void) | null,
  ) {}

  public show(element: HglElement) {
    this.hglElement = element;
    this.hglCurrentChildElement = element.children[0];
    const localStorageGuide = this.localStorageHelper.getNextGuideElementId();
    if (localStorageGuide) {
      const child = element.children.find(
        ({ id }) => id === localStorageGuide.nextElementId
      );
      if (child) {
        this.hglCurrentChildElement = child;
      }
    } else {
      this.localStorageHelper.addNextGuideElementId(
        this.hglElement.id,
        this.hglCurrentChildElement.id
      );
    }

    this.actions = {
      onNext: (isClicked?: boolean) => {
        const { type, nextElementId, data } = this.hglCurrentChildElement;
        this.localStorageHelper.addNextGuideElementId(
          this.hglElement.id,
          nextElementId
        );

        if (type === "move") {
          this.resetUIState();
          if (!isClicked) {
            this.currentClientElement?.click();
          }
        }

        if (data?.sameSite) {
          if (nextElementId) {
            this.showNext(nextElementId);
          } else {
            this.onClose!();
          }
        }
      },
      onEnd: () => {
        this.onClose!();
      },
    };

    this.showNext(this.hglCurrentChildElement.id);
  }

  public close(): void {
    this.resetUIState();
    this.currentClientElement = null;
    this.actions = null;
  }

  private resetUIState(): void {
    if (!this.containerElement) {
      return;
    }
    this.containerElement.destroy();
    this.overlayHelper.closeDimmedOverlays();
    this.overlayHelper.closeRootOverlay();
    this.domEventHelper.removeEventsHandler();
  }

  public update(childElement: HglChildElement): void {
    this.hglCurrentChildElement = childElement;
    if (!this.containerElement) {
      return
    }
    const rootOverlay = this.overlayHelper.getRootOverlay()
    rootOverlay.removeChild(this.containerElement.htmlElement);
    this.containerElement.destroy()
    
    this.elementFactory?.updateConfig(childElement.config)
    this.containerElement = this.elementFactory!.createContainer(childElement.blocks[0])
    rootOverlay.appendChild(this.containerElement.htmlElement);

    const { position } = this.hglCurrentChildElement.data;
    if (childElement.path) {
      updateGuideElementPosition(
        this.currentClientElement!,
        this.containerElement.htmlElement,
        position,
        this.isHeaderElement()
      );
      this.elementFactory?.themeApplier.applyConfig(this.containerElement)
    } else {
      this.positionFreeFlowElement(childElement);
    }
  }

  private showNext(nextElementId: string): void {
    this.resetUIState();

    setTimeout(() => this.showNextDelayed(nextElementId), 300);
  }

  private showNextDelayed(nextElementId: string): void {
    let nextId: string | null = nextElementId;
    const currentElement =
      nextId &&
      this.hglElement.children.find((element) => element.id === nextId);
    if (!currentElement) {
      return;
    }

    let elementOnPath = null;
    if (currentElement.path) {
      const result = findByElementPath(currentElement.path);

      if (!result) {
        return;
      }
      elementOnPath = <HTMLElement>result.el
    }

    this.hglCurrentChildElement = currentElement!;
    this.showElement(elementOnPath);
  }

  private showElement(element: HTMLElement | null) {
    this.currentClientElement = element;
    this.elementFactory = new HglGuideElementFactory(
      this.hglElement.type,
      this.hglCurrentChildElement.type,
      this.actions!,
      this.hglCurrentChildElement.config,
      this.hglElement.theme,
      !this.hglCurrentChildElement.path,
      );
      const containerObj = this.elementFactory.createContainer(this.hglCurrentChildElement.blocks[0]);
      this.containerElement = containerObj;
      this.updateStep();

    const rootOverlay = this.overlayHelper.getRootOverlay();
    this.overlayHelper.createDimmedOverlays();

    if (!element) {
      const bottom = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      const defaultRect = {
        width: 0,
        height: 0,
        right: 0,
        top: 0,
        left: 0,
        bottom,
        x: 0,
        y: 0,
        toJSON: () => {},
      };
      this.overlayHelper.updateOverlayPositions(defaultRect);
      rootOverlay.append(containerObj.htmlElement);
      this.positionFreeFlowElement(this.hglCurrentChildElement);
      return;
    }

    document.documentElement.scrollTop = document.body.scrollTop =
      this.hglCurrentChildElement.data.scroll;

    this.domEventHelper.setEventsHandler(() => {
      const rect = element.getBoundingClientRect();
      this.overlayHelper.updateOverlayPositions(rect);
      const { position } = this.hglCurrentChildElement.data;
      updateGuideElementPosition(element, this.containerElement.htmlElement, position, this.isHeaderElement())
      this.elementFactory?.themeApplier.applyConfig(this.containerElement)
    });

    const rect = element.getBoundingClientRect();
    const transOverlay = this.overlayHelper.getTransparentOverlay();
    transOverlay.className += " solid-border";
    this.overlayHelper.updateOverlayPositions(rect);

    if (this.hglCurrentChildElement.type === "move") {
      transOverlay.style.pointerEvents = "none";
      this.overlayHelper.addTransparentClickListener(this.actions!.onNext);
    } else {
      transOverlay.style.pointerEvents = "all";
    }
    setTimeout(() => {
      rootOverlay.appendChild(containerObj.htmlElement);
      const { position } = this.hglCurrentChildElement.data;
      updateGuideElementPosition(element, containerObj.htmlElement, position, this.isHeaderElement());
      this.elementFactory?.themeApplier.applyConfig(this.containerElement)

      if (!this.hglCurrentChildElement.nextElementId && this.hglElement.children.length > 1) {
        setTimeout(() => {
          confetti({
            particleCount: 100,
            spread: 70,
            zIndex: 9999,
            origin: { ...this.getConfettiPosition() }
          });
        }, 500)
      }
    }, 200);
  }

  private updateStep() {
    // const step = (this.hglCurrentChildElement.blocks[0].content as HglBuildingBlock[]).find(
    //   ({ type }) => type === "step"
    // );
    // if (step) {
      const allSteps = this.hglElement.children.length;
      const currentStep =
        this.hglElement.children.indexOf(this.hglCurrentChildElement) + 1;
      const step = this.containerElement.htmlElement.querySelector('.hgl-block-text.step')
      if (step) {
        step.innerHTML = `${currentStep} of ${allSteps}`;
      }
    // }
  }

  private getConfettiPosition(): { x: number, y: number} {
    const { y, x, width } = this.containerElement!.htmlElement.getBoundingClientRect();

    const position = {
      x: (x + (width / 2)) / window.innerWidth,
      y: (y + 100) / window.innerHeight,  // 50 so it's within the item
    }
    console.log("position", position)
    return position
  }

  private positionFreeFlowElement(childElement: HglChildElement): void {
    const position = childElement.data.position;
    const style = this.containerElement!.htmlElement.style;
    style.animationDuration = "300ms";
    const rect = this.containerElement!.htmlElement.getBoundingClientRect();

    const verticalPosition = position.split(" ")[0];
    switch (verticalPosition) {
      case "top": {
        style.top = "1rem";
        style.bottom = "unset";
        style.transform = "unset";
        style.animationName = "fadeInTop";
        break;
      }
      case "center": {
        if (position.split(" ").length === 1) {
          style.left = `calc((100vw - ${rect.width}px)/2)`;
          style.top = `calc((100vh - ${rect.height}px)/2)`;
          style.right = "unset";
          style.bottom = "unset";
          style.animationName = "fadeInBottom";
          return;
        } else {
          style.top = `calc((100vh - ${rect.height}px)/2)`;
          style.bottom = "unset";
        }
        break;
      }
      case "bottom": {
        style.bottom = "1rem";
        style.top = "unset";
        style.transform = "unset";
        style.animationName = "fadeInBottom";
        break;
      }
    }
    const horizontalPosition = position.split(" ")[1];
    switch (horizontalPosition) {
      case "left": {
        style.left = "1rem";
        style.right = "unset";
        style.animationName = "fadeInLeft";
        break;
      }
      case "center": {
        style.left = `calc((100vw - ${rect.width}px)/2)`;
        style.right = "unset";
        break;
      }
      case "right": {
        style.right = "1rem";
        style.left = "unset";
        style.animationName = "fadeInRight";
        break;
      }
    }
  }

  private isHeaderElement(): boolean {
    return !!(this.hglCurrentChildElement.blocks[0].content as HglBuildingBlock[]).find(({ type }) => type === 'header')
  }
}
