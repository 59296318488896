import confetti from "canvas-confetti";
import { HglBuildingBlock, HglBuildingBlockType } from "../model/element.types";
import { ContainerElement } from "./container.element";
import { ElementFactory } from "./element.factory";
import { HglUiElement } from "./element.types";

export class CheckElement implements HglUiElement {
  public id: string;
  public type: HglBuildingBlockType;
  public htmlElement: HTMLElement | null = null;
  public content: HglBuildingBlock[];
  public childColumn: ContainerElement | null = null;

  constructor(
    { id, type, content, data, onUpdated }: any,
    private factory: ElementFactory
  ) {
    this.id = id;
    this.type = type;
    this.content = content;
    const checklistItem = document.createElement("div");
    checklistItem.id = id;
    checklistItem.className = "hgl-element-reset hgl-checklist-item";

    const checklistHeader = document.createElement("div");
    checklistHeader.className = "hgl-element-reset hgl-checklist-item-header";

    const check = document.createElement("div");
    check.className = "hgl-element-reset hgl-block-check";
    
    if (data.lastChecked) {
      setTimeout(() => {
        this.maybeAppendDoneElement(data, check, checklistHeader, checklistItem);
        confetti({
          particleCount: 100,
          spread: 70,
          zIndex: 9999,
          origin: { ...this.getConfettiPosition() }
        });
      }, 1000)
    } else {
      this.maybeAppendDoneElement(data, check, checklistHeader, checklistItem);
    }
    
    checklistHeader.appendChild(check);
    
    const textElement = factory.create(content[0]);
    checklistHeader.appendChild(textElement.htmlElement!);
    checklistItem.appendChild(checklistHeader);

    checklistItem.addEventListener("click", () => {
      if (!this.childColumn?.htmlElement) {
        return;
      }
      onUpdated?.(this.id);
    });
    
    if (content.length > 1) {
      this.childColumn = factory.create(content[1]) as ContainerElement;
      this.maybeApplyCollapsedClass(data, this.childColumn.htmlElement);

      const collapseContainer = document.createElement("div");
      collapseContainer.className = "hgl-block-check-collapse-container"
      collapseContainer.appendChild(this.childColumn.htmlElement!);
      checklistItem.appendChild(collapseContainer)
    }

    this.htmlElement = checklistItem;
  }

  public lightUpdate({ content, data, id }: any) {
    if (id !== this.id) {
      return;
    }

    if (content.length > 1 && this.childColumn) {
      this.maybeApplyCollapsedClass(data, this.childColumn.htmlElement);
    }

    // this.maybeAppendDoneElement(
    //   data,
    //   this.htmlElement!.firstElementChild!.firstElementChild!,
    //   this.htmlElement!.firstElementChild!,
    //   this.htmlElement!
    // );
  }

  public update({ id, content, data }: any) {
    if (id !== this.id) {
      return;
    }
    
    this.maybeAppendDoneElement(
      data,
      this.htmlElement!.firstElementChild!.firstElementChild!,
      this.htmlElement!.firstElementChild!,
      this.htmlElement!
    );
    this.htmlElement!.firstElementChild!.children[1]!.innerHTML = content[0].content;

    if (this.childColumn && content.length > 1) {
      this.maybeApplyCollapsedClass(data, this.childColumn.htmlElement);
      this.childColumn.update(content[1])
      return
    }

    if (this.childColumn) {
      this.destroy(this.childColumn.id)
      return
    }
    
    if (content.length > 1 && content[1].length) {
      this.childColumn = this.factory.create(content[1]) as ContainerElement
      
      const collapseContainer = document.createElement("div");
      collapseContainer.className = "hgl-block-check-collapse-container"
      collapseContainer.appendChild(this.childColumn.htmlElement!);
      this.htmlElement!.append(collapseContainer)
      return
    }

    if (!content.length) {
      this.htmlElement!.parentNode!.removeChild(this.htmlElement!)
      this.destroy()
      return
    }

  }

  public destroy(id?: string): void {
    if (this.childColumn?.htmlElement) {
      const childColumnId = this.childColumn.id
      this.htmlElement!.children[1].removeChild(this.childColumn.htmlElement);
      this.htmlElement!.removeChild(this.htmlElement!.children[1])
      this.childColumn.destroy();
      this.childColumn = null;

      if (id === childColumnId) {
        return
      }
    }
    this.htmlElement = null;
  }

  private getConfettiPosition(): { x: number, y: number} {
    const { y, x, width } = this.htmlElement!.getBoundingClientRect();

    const position = {
      x: (x + (width / 2)) / window.innerWidth,
      y: (y + 50) / window.innerHeight, // 50 so it's directly on the item
    }
    console.log("position", position)
    return position
  }

  private maybeAppendDoneElement({ isChecked }: any, check: Element, checklistHeader: Element, checklistItem: Element): void {
    if (isChecked) {
      const doneCheck = document.createElement('div')
      doneCheck.className = "hgl-block-check-done"
      check.appendChild(doneCheck)
    }
    this.maybeApplyClass(isChecked, 'hgl-block-check--done', check)
    this.maybeApplyClass(isChecked, 'hgl-checklist-item--done', checklistItem)
    this.maybeApplyClass(isChecked, 'hgl-checklist-item-header--done', checklistHeader)
  }

  private maybeApplyCollapsedClass({ isClicked }: any, element: Element): void {
    this.maybeApplyClass(
      !isClicked,
      "hgl-block-container-element--collapsed",
      element
    );
  }

  private maybeApplyClass(
    value: boolean,
    className: string,
    element: Element
  ): void {
    if (value) {
      if (!element.classList.contains(className)) {
        element.classList.add(className);
      }
    } else {
      element.classList.remove(className);
    }
  }
}
