import { HglBuildingBlock, HglBuildingBlockType } from '../model/element.types';
import { HglUiElement } from './element.types';

export class EndButtonElement implements HglUiElement {
  public id: string;
  public type: HglBuildingBlockType;
  public htmlElement: HTMLElement | null = null;
  public content: string | HglBuildingBlock[];

  private onEndListener: ((event: Event) => void) | null = null;

  constructor({ type, content, onEnd }: any) {
    this.id = 'hgl-block-end-button';
    this.type = type;
    this.content = content;
    let endButton = document.createElement('button');
    endButton.className = 'hgl-element-reset hgl-block-button hgl-block-button--secondary';
    endButton.innerHTML = content;
    endButton.id = this.id;

    this.onEndListener = (event) => {
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();

      onEnd();
    };
    endButton.addEventListener('click', this.onEndListener);

    this.htmlElement = endButton;
  }

  public lightUpdate(data: any) {}
  
  public update({ id, content }: any) {
    if (id !== this.id) {
      return
    }
    this.htmlElement!.removeEventListener('click', this.onEndListener!);
    this.onEndListener = (event) => {
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();
    };
    this.htmlElement!.addEventListener('click', this.onEndListener);
    this.htmlElement!.innerHTML = content;
  }

  public destroy(): void {
    this.htmlElement?.removeEventListener('click', this.onEndListener!);
    this.htmlElement = null;
  }
}
