import { HglBuildingBlockType } from "../model/element.types";
import { HglUiElement } from "./element.types";

export class DelimiterElement implements HglUiElement {
    public id: string;
    public type: HglBuildingBlockType
    public htmlElement: HTMLElement | null = null;
    public content = undefined;
  
    constructor({ id, type }: any) {
      this.id = id;
      this.type = type;
      const delimiter = document.createElement('div');
      delimiter.id = id;
      delimiter.className = 'hgl-element-reset hgl-block-delimiter';

      this.htmlElement = delimiter
    }

    public lightUpdate(data: any) {}
  
    public update(): void {

    }

    public destroy(): void {

    }
}