import { toPx } from '../utils/common.utils';

export function updateGuideElementPosition(
  clientElement: HTMLElement,
  containerElement: HTMLElement,
  position: string,
  arrowColored: boolean
) {
  const style = containerElement.style;
  style.animationDuration = '500ms'
  const containerRect = containerElement.getBoundingClientRect();
  const arrow = containerElement.firstChild! as HTMLElement;

  const positionSplit = position.split(' ');
  const arrowDimens = 15;
  let containerPosition = 0;

  let arrowPosition = positionSplit.length > 1 ? positionSplit[1] : null;
  let arrowClass = '';
  switch (arrowPosition) {
    case null:
      break;
    case 'top':
      arrowClass = 'bottom';
      containerPosition = containerRect.height - arrowDimens;
      break;
    case 'bottom':
      arrowClass = 'top';
      containerPosition = arrowDimens;
      break;
    case 'left':
      arrowClass = 'right';
      containerPosition = containerRect.width - arrowDimens;
      break;
    case 'right':
      arrowClass = 'left';
      containerPosition = arrowDimens;
      break;
    default:
      if (['top center', 'bottom center'].includes(position)) {
        containerPosition = containerRect.width / 2;
      } else {
        containerPosition = containerRect.height / 2;
      }
  }
  const rect = clientElement.getBoundingClientRect();
  let arrowOrientationClass = '';
  const margin = arrowPosition && arrowPosition !== 'center' ? (['top', 'left'].includes(arrowPosition) ? -10 : 10) : 0
  switch (positionSplit[0]) {
    case 'top':
      style.animationName = 'fadeInTop';
      arrowOrientationClass = 'bottom';
      style.top = toPx(rect.top - containerRect.height - arrowDimens - 1);
      style.left = toPx(rect.left + (rect.width + 4) / 2 - containerPosition - margin);
      break;
    case 'left':
      style.animationName = 'fadeInLeft';
      arrowOrientationClass = 'right';
      style.top = toPx(rect.top + rect.height / 2 - containerPosition - margin);
      style.left = toPx(rect.left - containerRect.width - arrowDimens - 1);
      break;
    case 'bottom':
      style.animationName = 'fadeInBottom';
      arrowOrientationClass = 'top';
      style.top = toPx(rect.top + rect.height + arrowDimens + 1);
      style.left = toPx(rect.left + (rect.width + 4) / 2 - containerPosition - margin);
      break;
    case 'right':
      style.animationName = 'fadeInRight';
      arrowOrientationClass = 'left';
      style.top = toPx(rect.top + rect.height / 2 - containerPosition + 1 - margin);
      style.left = toPx(rect.left + rect.width + arrowDimens);
      break;
    default:
      style.animationName = 'fadeInTop';
      arrowOrientationClass = 'center';
      style.top = toPx(rect.top + rect.height / 2 - containerRect.height / 2);
      style.left = toPx(
        rect.left + (rect.width + 4) / 2 - containerRect.width / 2
      );
      break;
  }
  arrow.className = `hgl-arrow hgl-arrow--${arrowOrientationClass}`;
  if (arrowClass) {
    arrow.className += ` hgl-arrow--${arrowOrientationClass}_${arrowClass}`;
  }

  if (arrowColored && ["right bottom","bottom right","bottom center","bottom left","left bottom"].includes(position)) {
    arrow.className += ' hgl-arrow--colored'
  }
}
