export function toPx(value: number) {
  return `${value}px`;
}

export function findByElementPath(path: string[]): { el: Node, type: 'id' | 'path' } | null {
  const elementById = document.getElementById(path[0])
  if (elementById) {
    return { el: elementById, type: 'id' }
  }

  let element: Node = document.body;
  for (let i = 0; i < path.length; i++) {
    const [nodeName, position] = path[i].split('!');
    const tempEl = element.childNodes[+position];
    if (tempEl?.nodeName === nodeName) {
      element = tempEl;
    } else {
      return null;
    }
  }
  return { el: element, type: 'path' };
}

export function getElementPath(element: Node | HTMLElement) {
  const path = [element.nodeName];
  let current = element;
  while (true) {
    const parent = current.parentElement;
    if (parent) {
      for (let i = 0; i < parent.childNodes.length; i++) {
        if (parent.childNodes[i] === current) {
          path[0] = `${path[0]}!${i}`;
          break;
        }
      }
      path.unshift(parent.nodeName);
      current = parent;
    } else {
      // remove HTML and BODY
      path.shift();
      path.shift();
      return path;
    }
  }
}
