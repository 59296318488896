import {
  HglBuildingBlock,
  HglBuildingBlockType,
  HglChildElementType,
  HglElementType,
} from "../model/element.types";
import { ElementFactory } from "./element.factory";
import { HglUiElement } from "./element.types";

export class ContainerElement implements HglUiElement {
  public id: string;
  public type: HglBuildingBlockType;
  public htmlElement: HTMLElement;
  public content: HglBuildingBlock[];
  public childElements: HglUiElement[] = [];

  constructor(
    { id, type, content }: any,
    parentType: HglElementType,
    childType: HglChildElementType,
    factory: ElementFactory,
    protected topLevel = false
  ) {
    this.id = id;
    this.type = type;
    this.content = content;
    const container = document.createElement("div");
    container.id = id;
    if (topLevel) {
      container.className = `hgl-element-reset hgl-${parentType}-element hgl-${childType}-block-container-element hgl-${childType}-block-container-element--${type}`;
    } else {
      container.className = `hgl-element-reset hgl-block-container-element hgl-block-container-element--${type}`;
    }

    this.htmlElement = container;
    this.addElements(factory);
  }

  public destroy(id?: string): void {
    if (!id) {
      this.childElements.forEach((el) => {
        if (!el.htmlElement) {
          return;
        }
        try {
          this.htmlElement.removeChild(el.htmlElement);
        } catch(err) {}
        el.destroy();
      });
      this.childElements = [];
      this.htmlElement.innerHTML = "";
      return;
    }

    if (this.id === id) {
      this.htmlElement.innerHTML = "";
      return;
    }

    const el = document.getElementById(id);
    if (!el) {
      return;
    }
    const removed = this.htmlElement.removeChild(el);
    if (removed) {
      this.childElements = this.childElements.filter((el) => el.id !== id);
    } else {
      this.childElements
        .filter(({ type }) => ["row", "column"].includes(type))
        .forEach((el) => {
          el.destroy(id);
        });
    }
  }

  public lightUpdate(data: any) {}

  public update({ content }: any): void {
    const blocks = content as HglBuildingBlock[];

    // blocks.forEach((block, index) => {
    //   if (this.childElements.find(({ id }) => block.id === id)) {
    //     return
    //   }
    //   const element = this.factory.create(block)

    // })

    this.childElements = this.childElements.filter((el) => {
      if (!blocks.find((block) => block.id === el.id)) {
        if (el.htmlElement) {
          this.htmlElement.removeChild(el.htmlElement);
        }
        el.destroy();
        return false;
      }
      return true;
    });

    this.childElements.forEach((el) => {
      const childData = blocks.find(({ id }) => el.id === id);
      el.update(childData);
    });
  }

  public addElements(factory: ElementFactory): void {
    this.content.forEach((el) => {
      const htmlEl = factory.create(el);
      if (!htmlEl.htmlElement) {
        return;
      }
      this.childElements.push(htmlEl);
      this.htmlElement.appendChild(htmlEl.htmlElement);
    });

    if (this.topLevel || this.type === 'header') {
      const lastChild = this.htmlElement.lastChild! as HTMLElement
      lastChild.style.paddingBottom = '10px'
      let firstIncludedElement: HTMLElement | null = null
      this.htmlElement.childNodes.forEach(node => {
        const element = (node as HTMLElement)
        
        const isExcluded = ['header', 'video', 'arrow', 'image', 'close'].find(clazz => element.className.includes(clazz))
        const isHeader = this.type === 'header' || element.className.includes('header')
        const isMedia = ['video', 'image'].find(clazz => element.className.includes(clazz))
        if (isMedia && !firstIncludedElement) {
          firstIncludedElement = element
          element.style.borderTopRightRadius = factory.themeApplier.theme.radius
          element.style.borderTopLeftRadius = factory.themeApplier.theme.radius
        }

        if (!isExcluded && !firstIncludedElement) {
          firstIncludedElement = element
          if (!isHeader) {
            firstIncludedElement.style.paddingTop = '10px'
          }
        }
      })
    }
  }
}
