import { OverlayHelper } from '../shared/helpers/overlay.helper';

export class ElementSelectionHelper {
  private moveListener: ((event: Event) => void) | null = null;
  private clickListener: ((event: Event) => void) | null = null;
  private scrollListener: ((event: Event) => void) | null = null;

  private hoveredElement: HTMLElement | null = null;
  private selectedElement: HTMLElement | null = null;

  private isAlreadyAdded = false;

  constructor(private overlayHelper: OverlayHelper) {}

  public start(onElementSelected: (element: HTMLElement) => void) {
    this.overlayHelper.getRootOverlay();
    let transparentOverlay = this.overlayHelper.getTransparentOverlay();
    transparentOverlay.style.pointerEvents = 'none';
    transparentOverlay.className += ' dashed-border';
    transparentOverlay.style.width = '0px';
    transparentOverlay.style.height = '0px';

    this.moveListener = ({ target }) => {
      const targetElement = target as HTMLElement;
      if (this.hoveredElement != targetElement) {
        this.overlayHelper.updateOverlayPosition(
          transparentOverlay,
          targetElement.getBoundingClientRect(),
          4
        );
        this.hoveredElement = targetElement;
      }
    };

    this.clickListener = (event) => {
      const currentElement = event.target as HTMLElement;
      // stop link and event propagation
      event.preventDefault();
      event.stopPropagation();

      if (
        !this.isSamePosition(
          currentElement.getBoundingClientRect(),
          transparentOverlay.getBoundingClientRect()
        )
      ) {
        return false;
      }
      transparentOverlay.className = transparentOverlay.className.replace(
        'dashed-',
        'solid-'
      );

      transparentOverlay.style.pointerEvents = 'all';
      this.destroyListeners();
      this.selectedElement = currentElement;
      onElementSelected!(currentElement);
    };

    if (!this.isAlreadyAdded) {
      this.isAlreadyAdded = true;
      document.addEventListener('mousemove', this.moveListener);
      document.addEventListener('click', this.clickListener, true);
    }

    if (!this.scrollListener) {
      this.scrollListener = () => {
        if (this.selectedElement || this.hoveredElement) {
          const element = this.selectedElement ?? this.hoveredElement
          this.overlayHelper.updateOverlayPosition(
            transparentOverlay,
            element!.getBoundingClientRect(),
            4
          );
        }
      };
      document.addEventListener('scroll', this.scrollListener);
    }
  }

  public deselect(path: string[]) {
    if (!path) {
      return;
    }

    this.destroy();
  }

  public destroy() {
    this.overlayHelper.closeRootOverlay();

    this.destroyListeners();
    document.removeEventListener('scroll', this.scrollListener!);
    this.scrollListener = null;

    this.selectedElement = null;
  }

  private destroyListeners() {
    this.isAlreadyAdded = false;
    if (this.moveListener) {
      document.removeEventListener('mousemove', this.moveListener!);
      document.removeEventListener('click', this.clickListener!, true);
      // document.removeEventListener('scroll', this.scrollListener!);
    }
    this.moveListener = null;
    this.clickListener = null;
    this.hoveredElement = null;
    // this.scrollListener = null;
  }

  public isSamePosition(rect1: DOMRect, rect2: DOMRect): boolean {
    return (
      rect1.x === rect2.x + 2 &&
      rect1.y === rect2.y + 2 &&
      rect1.height === rect2.height - 4 &&
      rect1.width === rect2.width - 4 &&
      rect1.left === rect2.left + 2 &&
      rect1.right === rect2.right - 2 &&
      rect1.top === rect2.top + 2 &&
      rect1.bottom === rect2.bottom - 2
    );
  }
}
